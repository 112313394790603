@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:wght@200;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.elegant-heading {
  letter-spacing: -0.03em;
  font-weight: 300;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.animate-scroll {
  animation: scroll var(--scroll-speed, 20s) linear infinite;
  animation-direction: var(--scroll-direction, normal);
  will-change: transform;
}